@import "./material";

:root {
  --white: #ffffff;
  --black: #000000;
  --dark-gray: #dddddd;
  --gray: #eeeeee;
  --orange: #f17201;
  --green: #00a200;
  --blue: #01a1ff;
  --pink: #ea2e92;
  --primary: var(--orange);
  --on-primary: var(--white);
}

html, body {
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.25rem;
}

.h3 {
  font-size: 1.15rem;
}

.h4 {
  font-size: 1.10rem;
}

.bg-gray {
  background-color: #aaa !important;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-black {
  color: var(--black);
}

.text-primary {
  color: var(--primary);
}

.text-orange {
  color: var(--orange);
}

.text-green {
  color: var(--green);
}

.text-blue {
  color: var(--blue);
}

.text-pink {
  color: var(--pink);
}

.bold {
  font-weight: bold;
}

.mt-1 {
  margin-top: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  label {
    color: var(--black);
    display: block;
    margin-bottom: .5rem;
  }
}

.form-control {
  background-color: var(--dark-gray);
  box-sizing: border-box;
  border-radius: .25rem;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  width: 100%;
}

.page-container {
  margin: auto;
  margin-top: 5rem;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: 768px) {
    max-width: 85vw;
  }

  @media (min-width: 1400px) {
    max-width: 75vw;
  }
  @media (min-width: 1400px) {
    max-width: 50vw;
  }
}
